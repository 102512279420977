import React from "react"
import { Heading } from "@chakra-ui/core"

type Props = { color?: string; center?: boolean }

export const Heading1: React.FC<Props> = ({ children, color, center }) => {
  return (
    <Heading
      as="h1"
      textAlign={center ? "center" : ["left", "left", "left"]}
      fontSize={["1.6rem", "1.8rem", "2rem", "3rem"]}
      paddingX={["0rem", "0rem"]}
      fontWeight="bold"
      letterSpacing="0.3rem"
      marginTop={["2rem", "2rem", "0.2rem"]}
      color={color ? color : "black"}
    >
      {children}
    </Heading>
  )
}
